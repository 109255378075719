<template>
  <div class="settings" style="min-width: 1170px">
    <div class="explore-header">
      <div style="font-size: 28px; font-weight: 500; color: #ffffff; padding-top: 75px;">
        Ne tür bir işe ihtiyacın var?
      </div>
      <input id="explore-search-box" v-model="searchTerm" placeholder="Kategori, iş ilanı, kullanıcı..." type="text">

      <img @click="searchGigs(searchTerm)" style=" position: relative; right: 40px; top: 20px;"
           src="https://gcdn.bionluk.com/site/icon/aws/icon_search_2.svg">
    </div>

    <div class="explore-category-div">
      <div class="explore-categories" v-for="(category, index) in parentCategories">
        <router-link :to="'/freelancer-bul/' + category.slug" style="text-decoration: none;">
          <p class="explore-category-name"><span style="margin-right: 10px;"><img class="explore-category-img" :src="category.icon"/></span> {{category.name}}
          </p>
        </router-link>
        <div v-if="parentCategories.length !== index +1" class="explore-category-separator"></div>
      </div>
    </div>


    <div class="explore-marketplace-gig-container" :style="index%2 ===0 ? 'background-color: #ffffff;' : 'background-color: #f4f5f7;'"
         v-for="(gigList,index) in gigLists">
      <h1>{{gigList.name}}</h1>

        <div style="display: flex; margin-left: -30px;" >
          <single-gig :impression-u-u-i-d="impressionUUID" :clicked-from="'default'" v-for="(gig, gindex) in gigList.gigs" :key="gindex" :item="gig" style="margin-right: 30px;"/>
        </div>

    </div>

    <div v-if="blogViews.length" class="mainpage-marketplace-body" style="min-width: 1170px; background-color: #f4f5f7">
      <h1>
        <span style="font-weight: 600;">Bi Blog</span>
        <br>
        <span style="margin-top: 17px; font-size: 22px; font-weight: 300; color: #8b95a1;">
        En güncel haberler ve ipuçları için blogumuza göz at.
        </span>
      </h1>

      <div style="width: 1170px; margin: 0 auto 60px; ">
        <div style="display: flex; justify-content: space-between">
          <BlogView v-for="(blogView, index) in blogViews" :blog-view="blogView" :key="blogView.pathToGo + index" />
        </div>
      </div>

      <div class="mainpage-learnmore-div">
        <router-link style="text-decoration: none;" to="/blog">Tüm Yazılar</router-link>
      </div>
      <div class="hiddendiv>"></div>
      <div class="hiddendiv>"></div>
    </div>

    <div class="mainpage-illustration">
      <h1>Neden <span style="font-weight: 600;">Bionluk?</span></h1>
      <div class="illustration-body">
        <div class="illustration-inner" style="margin-right: 41px;">
          <img style="height: 220px; width: 220px;" src="https://gcdn.bionluk.com/site/general/startups_3_bi.png"/>
          <p class="illustration-main-paragraph">Çeşitlilik</p>
          <p class="illustration-paragraph">
            Konusunda uzmanlaşmış ve ustalaşmış freelancer’lar, Bionluk’un velinimetleri... İşte bu yüzden alanında uzman freelancer’larla çalışmaya özen gösteriyor ve ihtiyacına en uygun olanları sizlerle buluşturuyoruz.

          </p>
        </div>
        <div class="illustration-inner">
          <img style="height: 220px; width: 220px;" src="https://gcdn.bionluk.com/site/general/startups_1_bi.png"/>
          <p class="illustration-main-paragraph">Verimlilik</p>
          <p class="illustration-paragraph">
            Freelancer’larla çalışmanın faydaları saymakla bitmez; zamandan tasarruf, maddi tasarruf, işini seven insanlarla, severek çalışmak... Bionluk’ta her bütçeye uygun bir uzman bulmak mümkündür.

          </p>

        </div>
        <div class="illustration-inner" style="margin-left: 41px;">
          <img style="height: 220px; width: 220px;" src="https://gcdn.bionluk.com/site/general/startups_2_bi.png"/>
          <p class="illustration-main-paragraph">Zamandan Tasarruf</p>
          <p class="illustration-paragraph">
            Kısa dönem ve parça başı işlerinde freelancer’larla çalışmayı tercih edersen, Bionluk’la uzun vadede ne kadar büyük bir zaman tasarrufu ettiğini fark edeceksin. Bu keyfi paylaşmak, işimizi sevdiren duygulardan yalnızca biri.

          </p>

        </div>
      </div>
    </div>

    <div class="mainpage-bottom" v-if="!isLoggedIn">
      <h1>Geleceğin "çalışma" modeliyle bugünden tanışın.</h1>
      <div class="mainpage-bottom-button-div">
        <div @click="openOnboardingModal" to="/register"><button class="mainpage-bottom-button" >Hemen Başla!</button></div>
      </div>
    </div>

  </div>
</template>

<script>

import singleGig from "@/pages/components/singleGig";
import Api from "@/utils/request";
import BlogView from "@/pages/components/BlogView";

  export default {
    components: {BlogView, singleGig},
    name: "src-pages-body-marketplace-dashboard-v2",
    data() {
      return {
        impressionUUID:null,
        searchTerm: null,
        gigLists: null,
        blogViews: [],
        swiperOption: {
          notNextTick: true,
          direction: 'horizontal',
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          slidesPerView: 1,
          observeParents: true
        },
      }
    },

    methods: {
      openOnboardingModal(){
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.ONBOARDING,info:{url:null}});
      },
      searchGigs(searchTerm) {
        this.EventBus.$emit('equalizationForSearchTerm', searchTerm);
        this.$router.push(`/search/?term=${searchTerm}`);
      },
      getGigList() {
        this.api.general.getGigList()
          .then(({data}) => {
            let result = data;
            if (result.success) {


              let trackGigObj = [];
              result.data.forEach((gigl) => {
                gigl.gigs.forEach((gig, gigdex) => {
                  trackGigObj.push({
                    gig_id:gig.id,
                    seller_id:gig.seller_id,
                    order_id: gigdex + 1
                  })
                })
              });
              let trackingProps = {
                page:'categoryhome',
                placement:'default',
                info:trackGigObj,
                title: this.impressionUUID
              };
              this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);

              this.gigLists = result.data;
            } else {
            }
            this.$toasted.global.errorToast({description: result.message});
          }).catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },

      updateLikeStatusForGig(receivedGig) {
        this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.GIG, receivedGig.likeStatus.action, receivedGig.uuid,null,this.$store.state.componentMap.page)
          .then(({data}) => {
            let result = data;

            if (result.success) {
            } else {
              this.$router.push(result.data.redirect_url);
              this.revertLikeStatus(receivedGig);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.revertLikeStatus(receivedGig);
          });
        this.reverseLikeStatus(receivedGig);
      },

      revertLikeStatus(receivedGig) {
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: this.imageUrls.likeIcon.text
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: this.imageUrls.likedIcon.text
          };
        }
      },

      reverseLikeStatus(receivedGig) {
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: this.imageUrls.likedIcon.text
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: this.imageUrls.likeIcon.text
          };
        }
      },

    },

    watch: {
      searchTerm(newValue, oldValue) {
        if (newValue) {
          this.searchTerm = "";
          this.EventBus.$emit('dashboardSearch', { searchTerm: newValue});
          document.getElementById("main-search").focus();
        }
      }
    },
    created() {
      this.impressionUUID = this.Helper.generateUUID();
      this.getGigList();
      Api.get('/blog-views/freelancer-bul')
        .then(({ data }) => {
          this.blogViews = data
        })
    }

  }
</script>

<style scoped lang="scss">

  .explore-header {
    margin: 0 auto;
    height: 270px;
    text-align: center;
    background-image: linear-gradient(79deg, rgba(230,50,98, 0.9), rgba(253,64,86, 0.9));
  }

  #explore-search-box {
    margin-top: 34px;
    width: 750px;
    font-size: 16px;
    padding: 0px 0px 0 20px !important;
    height: 60px;
    background-color: #ffffff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  }

  #explore-search-box::placeholder {
    font-size: 16px;
    color: #a1a9a9;
  }

  .explore-category-div {

    height: 70px;
    background-color: #f4f5f7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .explore-categories {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .explore-category-name {
    font-size: 15px;
    color: #8b95a1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .explore-category-name:hover {
    color: #2d3640;
  }

  .explore-category-separator {
    width: 2px;
    height: 69.5px;
    border-right: solid 1px #d9dfe5;
    margin-left: 19px;
    margin-right: 21px;
  }

  /*giglist*/



  .explore-marketplace-gig-container {
    height: 500px;
    min-width: 1170px;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .explore-marketplace-gig-container h1 {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    margin-top: 53px;
    margin-bottom: 42px;
  }
  .swiper-button-prev {
    background-image: url(https://gcdn.bionluk.com/site/icon/icon_video_ok_left.svg);
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    background-size: 8px 8px;
    left: 0;
    opacity: 1;
    border-left: none;
  }

  .swiper-button-next {
    background-image: url(https://gcdn.bionluk.com/site/icon/icon_video_ok_right.svg);
    width: 36px;
    height: 36px;
    background-color: #ffffff;
    border: solid 1px #dfe4ec;
    background-size: 8px 8px;
    right: 0;
    opacity: 1;
    border-right: none;
  }

  .swiper-button-disabled {
    opacity: 0.7;
    pointer-events: all !important;
  }

  .marketplace-gigs-body {
    display: inline-block;
    margin-left: 29px;
    width: 263px;
    height: 296px;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    vertical-align: top;
    text-align: left;
    &:first-child {
      margin-left: 0;
    }
  }

  .marketplace-gigs-userinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    margin-top: 10px;
  }

  /*illustration*/
  .mainpage-illustration {
    padding-bottom: 50px;
    padding-top: 61px;
  }

  .mainpage-illustration h1 {
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    color: #000;
    margin: 0;

  }

  .illustration-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 43px;
  }

  .illustration-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .illustration-main-paragraph {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    margin-top: 22px;
  }

  .illustration-paragraph {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.44;
    text-align: center;
    color: #8b95a1;
    word-break: break-word;
    width: 350px;
    height: 152px;
    margin-top: 21px;
  }

  .mainpage-learnmore-div {
    width: 220px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px rgba(75,79,82, 0.2);
    margin: auto;
  }

  .mainpage-learnmore-div a {
    font-size: 16px;
    font-weight: 500;
    color: #2d3640;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    text-decoration: none;
  }


  /*bottom*/

  .mainpage-bottom {
    background-image: linear-gradient(79deg, rgba(230,50,98, 0.9), rgba(253,64,86, 0.9));
    overflow: hidden;
    position: relative;
    height: 158px;
    padding-top: 119px;
    padding-bottom: 119px;
  }

  .mainpage-bottom h1 {
    font-size: 42px;
    font-weight: 300;
    color: #ffffff;
    padding-bottom: 30px;
    margin: auto;
    text-align: center;
  }

  .mainpage-bottom-button-div {
    margin: auto;
    position: relative;
    width: 270px;
    height: 70px;

  }

  .mainpage-bottom-button {
    width: 270px;
    height: 70px;
    border-radius: 2px;
    background-color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    color: #2d3640;
    border: none;
  }

  .mainpage-bottom-button:hover {
    background-color: #f6f6f6 !important;
  }

  .bottom-right {
    position: absolute;
    display: flex;
    align-items: center;
    top: 30px;
    right: -215px;
  }

  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-to {
    animation: bounce-out .0s;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0.2);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes bounce-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .mainpage-marketplace-body {
    background-color: #f4f5f7;
    padding-top: 52px;
    padding-bottom: 60px;
  }

  .mainpage-marketplace-body h1 {
    font-size: 42px;
    font-weight: 300;
    text-align: center;
    color: #2d3640;
    padding-bottom: 20px;
  }

</style>
